import React, { Fragment, useState } from 'react';
import { Button, Select, Typography } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { siteURL, snipcartMode } from '../../config';

const BuyButton = ( { product, worksCode, worksTitle } ) => {

    const [ currentProduct, setCurrentProduct ] = useState( product[ 0 ] );

    const updateCurrentProduct = code => {
        const foundProduct = product.find( p => p.code === code );
        setCurrentProduct( foundProduct );
    }

    if( !siteURL ) {
        return <p>Sorry, cart currently disabled, please check back later.</p>
    }

    return (
        <Fragment>
            {
                product.length > 1 &&
                <Select style={ { width: 250 } } defaultValue={ currentProduct.code } onChange={ code => updateCurrentProduct( code ) }>
                    {
                        product.map( product => <Select.Option key={ product.code } value={ product.code }>{ product.name } (${ product.price })</Select.Option> )
                    }
                </Select>
            }
            <Button
                type="text"
                className="snipcart-add-item"
                data-item-id={ currentProduct.code }
                data-item-price={ currentProduct.price }
                data-item-url={ `${ siteURL }works/${ worksCode }/${ currentProduct.code }` }
                data-item-description={ currentProduct.description }
                data-item-file-guid={ snipcartMode === 'LIVE' ? currentProduct.data_item_file_guid : currentProduct.data_item_file_guid_test }
                data-item-name={ `${ worksTitle }: ${ currentProduct.name }` }
                icon={ <ShoppingCartOutlined /> }
            >
                Add to Cart
                {
                    snipcartMode === 'TEST' &&
                    <Typography.Text type="warning"> (TEST MODE)</Typography.Text>
                }
            </Button>
        </Fragment>
    )
}

export default BuyButton;
