import React, { useState } from 'react';
import { Button, Card, Col, Row, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useStaticQuery, graphql, withPrefix } from 'gatsby';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { Metadata } from '../../components';
import StandardLayout from '../../layouts/standard';
import ListenButton from './_ListenButton';
import BuyButton from './_BuyButton';

const WorksPage = () => {

	const [ currentTrack, setCurrentTrack ] = useState( false )

	const selectTrack = ( title, track ) => {
		setCurrentTrack( {
			...track,
			title: track.title || title
		} )
	}

	const closePlayer = () => {
		setCurrentTrack( false )
	}

	const { 
		allFile,
		allMarkdownRemark,
		markdownRemark
	} = useStaticQuery(
		graphql`
			query {
        		markdownRemark(fileAbsolutePath: {regex: "/content\/works/"}) {
					frontmatter {
			  			title
					}
					html
					htmlAst
		  		}
				allFile {
					nodes {
						absolutePath
						name
					}
				}
				allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/works\/(.+.md)/"}}) {
					nodes {
						fileAbsolutePath
						frontmatter {
						  	title
							audio {
								filename
								format
								title
							}
							product {
								name
								description
								code
						  		data_item_file_guid
								data_item_file_guid_test
								price
							}
						}
						html
					}
				}	  
      		}
    	`
	);
	
	return (
		<StandardLayout>
			<Metadata title={ markdownRemark.frontmatter.title } />
			{
				currentTrack && 
				<AudioPlayer
					className="works-audio-player"
					autoPlay
					header={ 
						<div className="works-audio-player-header">
							<Typography.Text strong>Currently Playing:</Typography.Text> 
							{ ' ' }
							<Typography.Text>{ currentTrack.title }</Typography.Text> 
							<Button type="text" onClick={ () => closePlayer() } icon={ <CloseOutlined /> } />
						</div> }
					src={ withPrefix( `works/${ currentTrack.filename }` ) }
				/>
			}
			<Typography.Title level={2}>{markdownRemark.frontmatter.title}</Typography.Title>
			<div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
			<Row gutter={ [ 16, 16 ] }>
				{
					allMarkdownRemark.nodes.map( worksItem => {
						const matchingFile = allFile.nodes.find( f => f.absolutePath === worksItem.fileAbsolutePath );
						return (
							<Col xs={ 24 } lg={ 12 } key={ matchingFile.name }>
								<Card 
									title={ worksItem.frontmatter.title } 
									extra={ worksItem.frontmatter.audio && <ListenButton selectTrack={ selectTrack } { ...worksItem.frontmatter } />  }
									actions={ [ <BuyButton worksCode={ matchingFile.name } worksTitle={ worksItem.frontmatter.title } { ...worksItem.frontmatter } /> ] }>
									<div dangerouslySetInnerHTML={{ __html: worksItem.html }} />
								</Card>
							</Col>
						)
					})
				}
			</Row>
		</StandardLayout>
	)
}

export default WorksPage;
