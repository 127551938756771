import React from 'react';
import { Button } from 'antd';
import { CustomerServiceOutlined } from '@ant-design/icons';

const ListenButton = ({ title, audio, selectTrack }) => {
    return (
        <Button type="text" onClick={ () => selectTrack( title, audio ) } icon={ <CustomerServiceOutlined /> }>Listen</Button>
    )
}

export default ListenButton;